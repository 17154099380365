import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GENERAL_OCCUPANCY_REQUEST,
  PERFOMANCE_HIGHLIGHTS_REQUEST
} from "../../../../redux/actions";
import { generalOccupancySelector } from "../../../../redux/selectors/occupancyRateSelector";
import { perfomanceHighlightsSelector } from "../../../../redux/selectors/perfomanceHighlightsSelector";
import { periodList } from "../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../common/PeriodDropdown/PeriodDropdown";
import { Highlight } from "../../../common/BasicHighlight/BasicHighlight";
import { OccupancyHighlight } from "../../../common/CircleOccupancyHighlight/CircleOccupancyHighlight";
import classes from "./PerfomanceHighlights.module.scss";
import { isNumber } from "../../../../utils/commonUtils";
import { L3060D, L12M, N30D, L30D } from '../../../../utils/constants/filterConstants';
import {isMobileModeSelector} from "../../../../redux/selectors/appStatusSelector";

export const PerfomanceHighlights = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const previousPeriod = period === N30D ? L30D : L3060D;
  const data = useSelector(perfomanceHighlightsSelector(period));
  const previousData = useSelector(perfomanceHighlightsSelector(previousPeriod));
  const isMobileView = useSelector(isMobileModeSelector);
  const {
    uniqueReservations,
    totalBookedNights,
    avarageDailyRates,
    netReservationIncome
  } = data || {};

  const {
    uniqueReservations: previousUniqueReservations,
    totalBookedNights: previousTotalBookedNights,
    avarageDailyRates: previousAvarageDailyRates,
    netReservationIncome: previousNetReservationIncome,
  } = previousData || {};

  const generalOccupancy = useSelector(generalOccupancySelector(period));

  useEffect(() => {
    dispatch({
      type: GENERAL_OCCUPANCY_REQUEST,
      payload: { param: period }
    });
    dispatch({
      type: PERFOMANCE_HIGHLIGHTS_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.perfomanceHighlightsWrapper}>
      <>
        <div className={classes.highlightsHeader}>
          <h4>Reservations</h4>
          <PeriodDropdown
            className={classes.outlinedDropdown}
            period={period}
            periodList={periodList}
            setPeriod={setPeriod}
          />
        </div>
        <div className={classes.highlights}>
          {isNumber(Number(generalOccupancy?.total)) && (
            <>
            <OccupancyHighlight              
              label={"Occupancy Rate"}
              rate={generalOccupancy?.total}
              previousRate={generalOccupancy?.last30_60_days}
              reverse={isMobileView}
              showIndicator={ period !== N30D && period !== L30D}
            />
            <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(uniqueReservations) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Reservations"
                tooltip={"Represents the amount of reservations Checking-in during the selected period"}
                value={uniqueReservations}
                previousValue={previousUniqueReservations}
                reverse={isMobileView}
                showDynamicIndicator={period !== N30D && period !== L30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(totalBookedNights) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Occupied Nights"
                tooltip={
                  "Represents the number of nights that your property was occupied for the given period"
                }
                value={totalBookedNights}
                previousValue={previousTotalBookedNights}
                reverse={isMobileView}
                showDynamicIndicator={period !== N30D && period !== L30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(avarageDailyRates) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Average Daily Rate"
                prependSymbol={"$"}
                tooltip={
                  "Represents the average daily rate per night for the given period after discounts (e.g. weekly & monthly discounts)."
                }
                value={avarageDailyRates}
                previousValue={previousAvarageDailyRates}
                reverse={isMobileView}
                showDynamicIndicator={period !== N30D && period !== L30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(netReservationIncome) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Net Reservation Income"
                prependSymbol={"$"}
                tooltip={
                  "This is your net income from secured reservations minus fees. This number is calculated by taking the Net Accommodation Fare subtracting the Channel, Credit Card and Booking Fees."
                }
                value={netReservationIncome}
                previousValue={previousNetReservationIncome}
                reverse={isMobileView}
                showDynamicIndicator={period !== N30D && period !== L30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
        </div>
      </>
    </div>
  );
};
